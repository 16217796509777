<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center style="padding-bottom: 200px">
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12" flat>
          <v-toolbar dark color="#1234" flat>
            <v-toolbar-title>Login in</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="mdi-account"
                name="password"
                :label="$t('login.username')"
                type="text"
                v-model="username"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                :label="$t('login.password')"
                type="password"
                v-model="password"
                :rules="[rules.required]"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="tt" class="white--text" @click="login">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    ...mapState("auth", ["username", "password"]),
    username: {
      get() {
        return this.$store.state.auth.username;
      },
      set(value) {
        this.setUsername(value);
      },
    },
    password: {
      get() {
        return this.$store.state.auth.password;
      },
      set(value) {
        this.setPassword(value);
      },
    },
  },
  methods: {
    ...mapActions("auth", ["login", "setUsername", "setPassword"]),
  },
};
</script>
